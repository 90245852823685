/*! *********************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import useLocalStorage from 'use-local-storage';
import _min from 'lodash/min';
import _max from 'lodash/max';
import _head from 'lodash/head';
import _last from 'lodash/last';
import {
  isEmbed,
  sessionMode,
  sessionReferer,
  SESSION_MODE,
  SESSION_REFERER,
} from '../../utils/AppThemeUtils';

const LOCAL_DRAFT_KEY = '3d-assets-item-draft';
const LOCAL_SENSITIVE_KEY = '3d-assets-item-sensitive-hide';
const LOCAL_ITEM_SIZE_KEY = '3d-assets-item-size';
const REFERRERS = { PAINTER: 'painter' };

// The Items per page are linked to the sizes
const sizes = [75, 100, 150, 250, 330, 430];
const itemsPerPageBakedValue = [100, 85, 70, 60, 45, 30];

// The mode and referer are only get once at startup
const { searchParams } = new URL(window.location.href);
const queryMode = searchParams.get('mode');
const queryReferer = searchParams.get('referer');
queryMode && sessionStorage.setItem(SESSION_MODE, queryMode);
queryReferer && sessionStorage.setItem(SESSION_REFERER, queryReferer);

// The default grid item size
const DEFAULT_ITEM_SIZE =
  localStorage.getItem(LOCAL_ITEM_SIZE_KEY) || (isEmbed() ? 0 : 3);

function useAppTheme() {
  const theme = 'dark';

  // The items per page are only compute one at the start to avoid
  // changing the page length dynamically in the middle of a scroll
  const itemsPerPage = itemsPerPageBakedValue[DEFAULT_ITEM_SIZE];

  // Grid Item Size
  const [itemSize, setItemSize] = useLocalStorage(
    LOCAL_ITEM_SIZE_KEY,
    DEFAULT_ITEM_SIZE
  );

  // Sensitive Assets
  const [hideSensitive, setHideSensitive] = useLocalStorage(
    LOCAL_SENSITIVE_KEY,
    true
  );

  // Show Draft Assets
  const [showDraft, setShowDraft] = useLocalStorage(LOCAL_DRAFT_KEY, true);

  function zoom() {
    setItemSize(_min([itemSize + 1, sizes.length - 1]));
  }

  function unzoom() {
    setItemSize(_max([itemSize - 1, 0]));
  }

  return {
    mode: sessionMode(),
    isEmbed: isEmbed(),
    referer: sessionReferer(),
    theme,
    sizes,
    itemsPerPage,
    itemSize: {
      value: sizes[itemSize],
      zoom,
      unzoom,
      isMinZoom: _head(sizes) === sizes[itemSize],
      isMaxZoom: _last(sizes) === sizes[itemSize],
    },
    hideSensitive: { value: hideSensitive, setHideSensitive },
    showDraft: { value: showDraft, setShowDraft },
  };
}

export {
  LOCAL_DRAFT_KEY,
  LOCAL_SENSITIVE_KEY,
  LOCAL_ITEM_SIZE_KEY,
  SESSION_MODE,
  SESSION_REFERER,
  REFERRERS,
};
export default useAppTheme;
