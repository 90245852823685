/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import {
  analytics,
  AnalyticsTrackData,
  EVENTS_TYPE,
} from '@3di-cloud-web/analytics';
import { i18nLanguageDetector } from '@3di-cloud-web/i18n';

import { IS_PRODUCTION } from './config';
import {
  SESSION_MODE,
  SESSION_REFERER,
} from './components/AppTheme/useAppTheme';

const EVENT_PREFIX = 'SubstanceSourceEvent';

export async function init() {
  const options = new Map([
    ['environment', IS_PRODUCTION ? 'production' : 'stage'],
    [
      'additionalAccounts',
      IS_PRODUCTION ? 'adbadbsubstanceprod' : 'adbsubstancedev',
    ],
    ['property', 'global'],
  ]);
  await analytics.init(options);
}

export function track(pageName) {
  const location = global.location;

  // Extract lagnuage and region
  const language = i18nLanguageDetector.language;
  const region = navigator.language.split('-')[1];

  // Complete breadCrumbs with current page name
  const breadCrumbs = ['Home'];
  if (pageName !== 'Home') {
    breadCrumbs.push(pageName);
  }
  const sessionMode = sessionStorage.getItem(SESSION_MODE);
  const sessionReferer = sessionStorage.getItem(SESSION_REFERER);

  const data = new Map();
  data.set('page.pageInfo.siteSection', pageName);
  data.set(
    'page.pageInfo.template',
    location.pathname + location.search + location.hash
  );
  data.set('page.pageInfo.language', language);
  data.set('page.pageInfo.geoRegion', region);
  data.set('page.pageInfo.issueDate', new Date());
  data.set('page.pageInfo.breadCrumbs', breadCrumbs);
  if (!!sessionMode) data.set('page.pageInfo.embed', true);
  if (!!sessionReferer) data.set('page.pageInfo.referer', sessionReferer);

  analytics.track(new AnalyticsTrackData(data));
}

export function event({ category, action, label, value, nonInteraction }) {
  const sessionReferer = sessionStorage.getItem(SESSION_REFERER);
  analytics.event(`${EVENT_PREFIX} - ${action}`, {
    ...(category && { [EVENTS_TYPE.CATEGORY]: category }),
    ...(label && { [EVENTS_TYPE.LABEL]: label }),
    ...(value && { [EVENTS_TYPE.VALUE]: value }),
    ...(nonInteraction && { [EVENTS_TYPE.NON_INTERACTION]: nonInteraction }),
    ...(sessionReferer && { referer: sessionReferer }),
  });
}

export function formattedEvent({ category, label, action }) {
  event({ action: `${category} - ${label} - ${action}` });
}

export function cloudPlayerEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Cloud-player',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function popupEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Popup',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function filterEvent({ action, label }) {
  const category = 'assets-filter';
  event({ action: `${category} - ${label} - ${action}` });
}

export function userEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'User',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function assetEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Asset',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function transferEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Account transfer',
    action: `Account transfer - ${action}`,
    label,
    value,
    nonInteraction,
  });
}

export function homepageEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Homepage',
    action: `Homepage - ${action}`,
    label,
    value,
    nonInteraction,
  });
}

export function searchEvent({ action, label, value }) {
  event({
    category: 'Search',
    action: `Search - ${action}:${label} - ${value}`,
  });
}

export function themesEvent({ action, label, value }) {
  console.debug(`[ANALYTICS] Themes - ${action} - ${label}: ${value}`);

  event({
    category: 'Themes',
    action: `Themes - ${action} - ${label}: ${value}`,
  });
}
