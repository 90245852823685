/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import 'intersection-observer';

import { init as initAnalytics } from './Analytics';
import { init as initFeatures } from './services/Feature';

const App = lazy(() => import('./App'));

// Replacing state in history to avoid glitches on reload page
window.history.replaceState({}, '/');

initAnalytics();
initFeatures();

ReactDOM.render(
  <Suspense fallback={<div id="mainLoading" />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
