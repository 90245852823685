/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

/* eslint-disable no-template-curly-in-string */

// !!! CommonJS format intended
// It's used by NodeJS server as well

/**
 * @param {string} placeholder
 * @param {string} defaultValue
 */
const parseEnv = (placeholder, defaultValue) => {
  const isNode =
    typeof process !== 'undefined' && process.versions && process.versions.node;

  if (placeholder.startsWith('${env.')) {
    // If on NodeJS (${env.XXXX} placeholders are not usually replaced), then load envvars dynamically
    const envVarName = placeholder.replace('${env.', '').replace('}', '');
    if (isNode) {
      return process.env[envVarName] || defaultValue;
    }

    return process.env[`REACT_APP_${envVarName}`] || defaultValue;
  }

  // placeholder may have been replaced by an empty string (no envvar defined), thus we take the default
  // value in that case.
  return placeholder || defaultValue;
};

exports.ASSETS_BRAND_NAME = 'Substance 3D Assets';

exports.COMMUNITY_ASSETS_BRAND_NAME = 'Substance 3D Community Assets';

exports.IS_PRODUCTION = parseEnv('${env.IS_PRODUCTION}', 'false') === 'true';

exports.PUBLIC_URL = parseEnv(
  '${env.PUBLIC_URL}',
  'https://localhost.adobe.com:3000/assets'
);

exports.API_URL = parseEnv(
  '${env.API_URL}',
  'https://source-api-develop.dev.substance3d.io'
);

exports.GRAPHQL_URL = `${this.API_URL}/beta/graphql`;

exports.HOME_TITLE = parseEnv(
  '${env.HOME_TITLE}',
  'Substance 3D Assets Homepage'
);

exports.DOCUMENT_TITLE_SUFFIX = parseEnv(
  '${env.DOCUMENT_TITLE_SUFFIX}',
  'on Substance 3D Assets'
);

exports.CORPORATE_WEBSITE_URL = parseEnv(
  '${env.CORPORATE_WEBSITE_URL}',
  'https://www.substance3d.com'
);

exports.ADOBE_3D_URL = parseEnv(
  '${env.ADOBE_3D_URL}',
  'https://www.adobe.com/creativecloud/3d-augmented-reality.html'
);

exports.ADOBE_3D_COMPARE_PLANS_URL = parseEnv(
  '${env.ADOBE_3D_COMPARE_PLANS_URL}',
  'https://www.adobe.com/creativecloud/3d-augmented-reality/compare-plans.html'
);

exports.CLOUD_PLAYER_URL = parseEnv(
  '${env.CLOUD_PLAYER_URL}',
  'https://cloud-player.substance3d.com'
);

exports.RTE_VIEWER_URL = parseEnv(
  '${env.RTE_VIEWER_URL}',
  'https://rte-web-viewer-develop.dev.substance3d.io'
);

exports.CLOUD_PLAYER_ENABLED =
  parseEnv('${env.CLOUD_PLAYER_ENABLED}', 'true') === 'true';

exports.RTE_VIEWER_ENABLED =
  parseEnv('${env.RTE_VIEWER_ENABLED}', 'true') === 'true';

exports.CDN_ASSET_PREFIX = parseEnv('${env.CDN_ASSET_PREFIX}', 'source');

exports.ADOBEID_CLIENT_ID = parseEnv(
  '${env.ADOBEID_CLIENT_ID}',
  'substance-source'
);

exports.OT_SCRIPT_URL = parseEnv(
  '${env.OT_SCRIPT_URL}',
  'https://www.dev02.adobe.com/etc/beagle/public/globalnav/adobe-privacy/latest/privacy.min.js'
);

exports.FEDS_SCRIPT_URL = parseEnv(
  '${env.FEDS_SCRIPT_URL}',
  'https://www.dev02.adobe.com/etc.clientlibs/globalnav/clientlibs/base/feds.js'
);

exports.OT_DOMAIN_ID = parseEnv(
  '${env.OT_DOMAIN_ID}',
  'a3713b16-2e78-408d-b62a-29ce17bf764b-test'
);

exports.ADOBE_IMS_CONFIG = {
  client_id: this.ADOBEID_CLIENT_ID,
  environment: this.IS_PRODUCTION ? 'prod' : 'stg1',
  scope: 'account_type,openid,AdobeID,read_organizations',
};

exports.LOCALE_QUERY = 'lang';

exports.DEFAULT_LOCALE = 'en-US';

exports.CJK_LOCALES = ['zh-Hans', 'zh-Hant', 'ja-JP', 'ko-KR'];

exports.ADOBE_LOCALE_APP_NAME = 's3diweb';

exports.DOWNLOAD_PACKAGE_ENABLED =
  parseEnv('${env.DOWNLOAD_PACKAGE_ENABLED}', 'false') === 'true';

exports.ROUTES = {
  home: '/assets',
  notfound: '/assets/notfound',
  myassets: '/assets/myassets',
  organizationAssets: '/assets/organization/assets',
  myassetsDetails: '/assets/myassets/:assetId',
  assets: '/assets/assets',
  allassets: '/assets/allassets',
  assetsDetails: '/assets/assets/:assetId',
  allassetsDetails: '/assets/allassets/:assetId',
  collections: '/assets/collections',
  collection: '/assets/collections/:collectionId',
  collectionAssetDetails: '/assets/collections/:collectionId/asset/:assetId',
};

exports.ROUTES_ASSET_DETAILS = [
  exports.ROUTES.assetsDetails,
  exports.ROUTES.allassetsDetails,
  exports.ROUTES.myassetsDetails,
  exports.ROUTES.collectionAssetDetails,
];

exports.NEW_ASSET_INFO = parseEnv('${env.NEW_ASSET_INFO}', 'false') === 'true';

exports.PLAYER_CONFIG = {
  CLOUD_PLAYER_URL: this.CLOUD_PLAYER_URL,
  RTE_VIEWER_URL: this.RTE_VIEWER_URL,
  CLOUD_PLAYER_ENABLED: this.CLOUD_PLAYER_ENABLED,
  RTE_VIEWER_ENABLED: this.RTE_VIEWER_ENABLED,
  CDN_ASSET_PREFIX: this.CDN_ASSET_PREFIX,
};

exports.LMT_SERVICE_URL = this.IS_PRODUCTION
  ? 'https://lmt-g11n.adobe.io'
  : 'https://lmt-g11n-stage.adobe.io';

exports.LMT_SERVICE_CUSTOMER_TOKEN = this.IS_PRODUCTION
  ? '11ebea4bfde9b26a83739707c16b6c88'
  : '11ebea4b65a76cdd98a6012dfb997eee';

exports.DB_SUPPORTED_INDEXING_LOCALES = ['en-US'];

exports.TYPES_ENABLED = parseEnv(
  '${env.TYPES_ENABLED}',
  'substanceMaterial substanceAtlas substanceDecal model substanceIbl'
);

const TYPES_ARRAY = exports.TYPES_ENABLED.split(' ');

exports.FEATURE_ENGINE_COMPATIBILITY =
  parseEnv('${env.FEATURE_ENGINE_COMPATIBILITY}', 'false') === 'true';

exports.COMPLEXITY_FILTER_ENABLED =
  parseEnv('${env.COMPLEXITY_FILTER_ENABLED}', 'false') === 'true';

exports.TEAR_AND_WEAR_FILTER_ENABLED =
  parseEnv('${env.TEAR_AND_WEAR_FILTER_ENABLED}', 'false') === 'true';

exports.ASSET_TO_FEATURE_MAP = {
  substanceMaterial: 'MATERIALS_ENABLED',
  substanceAtlas: 'MATERIALS_ENABLED',
  substanceDecal: 'MATERIALS_ENABLED',
  model: 'MODELS_ENABLED',
  substanceModel: 'SUBSTANCE_MODELS_ENABLED',
  substanceIbl: 'LIGHTS_ENABLED',
};

exports.ASSET_TO_DOWNLOAD_FORMAT_FEATURE_MAP = {
  substanceMaterial: {
    sbsar: 'MATERIALS_SBSAR_DOWNLOAD_FORMAT_ENABLED',
    sbs: 'MATERIALS_SBS_DOWNLOAD_FORMAT_ENABLED',
  },
  substanceAtlas: {
    sbsar: 'MATERIALS_SBSAR_DOWNLOAD_FORMAT_ENABLED',
    sbs: 'MATERIALS_SBS_DOWNLOAD_FORMAT_ENABLED',
  },
  substanceDecal: {
    sbsar: 'MATERIALS_SBSAR_DOWNLOAD_FORMAT_ENABLED',
    sbs: 'MATERIALS_SBS_DOWNLOAD_FORMAT_ENABLED',
  },
  substanceIBL: {
    stage: 'LIGHTS_STAGE_DOWNLOAD_FORMAT_ENABLED',
    sbsar: 'LIGHTS_SBSAR_DOWNLOAD_FORMAT_ENABLED',
    exr: 'LIGHTS_EXR_DOWNLOAD_FORMAT_ENABLED',
  },
  model: {
    fbx: 'MODELS_FBX_DOWNLOAD_FORMAT_ENABLED',
    glb: 'MODELS_GLB_DOWNLOAD_FORMAT_ENABLED',
  },
};

exports.FEATURES = {
  // asset types
  MATERIALS_ENABLED: [
    'substanceMaterial',
    'substanceAtlas',
    'substanceDecal',
  ].every((el) => TYPES_ARRAY.includes(el))
    ? 'ON'
    : 'OFF',
  MODELS_ENABLED: TYPES_ARRAY.includes('model') ? 'ON' : 'OFF',
  SUBSTANCE_MODELS_ENABLED: TYPES_ARRAY.includes('substanceModel')
    ? 'ON'
    : 'OFF',
  LIGHTS_ENABLED: TYPES_ARRAY.includes('substanceIbl') ? 'ON' : 'OFF',
  // download formats
  MATERIALS_SBS_DOWNLOAD_FORMAT_ENABLED: 'ON',
  MATERIALS_SBSAR_DOWNLOAD_FORMAT_ENABLED: 'ON',
  MODELS_FBX_DOWNLOAD_FORMAT_ENABLED: 'ON',
  MODELS_GLB_DOWNLOAD_FORMAT_ENABLED: 'ON',
  LIGHTS_STAGE_DOWNLOAD_FORMAT_ENABLED: 'ON',
  LIGHTS_SBSAR_DOWNLOAD_FORMAT_ENABLED: 'ON',
  LIGHTS_EXR_DOWNLOAD_FORMAT_ENABLED: 'ON',
  // technical info Backplate
  LIGHTS_BACKPLATES_ENABLED: 'ON',
};
