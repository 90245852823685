/*! *********************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

/**
 * Exposes feature flags to the rest of the application.
 * Note that initFeatures() needs to be called before feature should be used.
 *
 * Feature is initialized from the application configuration and overrides in the URL. They are NOT dynamic,
 * they require a full page refresh before changes in the configuration or in the URL are processed by the application.
 *
 * @example
 * render() {
 *   if (Feature.isOff("test") {
 *     return null;
 *   }
 *
 *   if (Feature.isOn("test") {
 *     return <div>Successful test!</div>
 *   }
 * }
 */
import queryString from 'query-string';
import { FEATURES } from '../config';
import { isEmbed } from '../utils/AppThemeUtils';

const FEATURE_STATUS = {
  ON: 'ON',
  OFF: 'OFF',
};

const FEATURE_URL_KEYS = {
  ON: 'featuresOn',
  OFF: 'featuresOff',
};

// List for current state of the features
const FeaturesList = {};

/**
 * Convenience methods to check if a feature is enabled or disabled.
 * Note that features are off by default (if it doesn't exist or has an unrecognized value)
 * */
const Feature = {
  isOn: (featureName) =>
    FeaturesList[featureName] === undefined ||
    FeaturesList[featureName] === FEATURE_STATUS.ON,
  isOff: (featureName) => FeaturesList[featureName] === FEATURE_STATUS.OFF,
};

/**
 * Builds a map of feature-value pairs from the URL features list, where each item in the list is a key in the map
 * and the value is identical to the one provided.
 */
const buildFeaturesFromURL = (featureList, value) => {
  const features = {};
  (featureList || '')
    .split(',')
    .filter((item) => item)
    .forEach((featureName) => {
      features[featureName] = value;
    });

  return features;
};

const extractFeaturesFromUrl = () => {
  const search = queryString.parse(window.location.search);

  return {
    ...buildFeaturesFromURL(search[FEATURE_URL_KEYS.ON], FEATURE_STATUS.ON),
    ...buildFeaturesFromURL(search[FEATURE_URL_KEYS.OFF], FEATURE_STATUS.OFF),
  };
};

/**
 * On initialization, create a FeaturesList by first looking at the config and then
 * update the list by parsing the URL params.
 */
const init = () => {
  Object.assign(
    FeaturesList,
    isEmbed()
      ? // disable all features in embed mode
        Object.keys(FEATURES).reduce((features, featureName) => {
          features[featureName] = FEATURE_STATUS.OFF;
          return features;
        }, {})
      : // otherwise, use the config
        FEATURES,
    extractFeaturesFromUrl()
  );
};

export default Feature;
export { init };
