/*! *********************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

export const SESSION_MODE = '3d-assets-mode';
export const SESSION_REFERER = '3d-assets-referer';

const EMBED_MODE = 'embed';

export const sessionMode = () => sessionStorage.getItem(SESSION_MODE);
export const sessionReferer = () => sessionStorage.getItem(SESSION_REFERER);
export const isEmbed = () => sessionMode() === EMBED_MODE;
